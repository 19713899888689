import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

import ProductHero from './sliding-sash-window.jpg'

import WeatherSeals from './feature-dual-weather-seals-sliding-sash.png'
import SpiralBalance from './feature-spiral-balance.png'
import WeightsCord from './feature-weights-and-cord.png'
import ToughenedGlass from './feature-24mm-glazing.png'

const SlidingSash = () => (
  <Layout>
    <Helmet title="Sliding Sash Windows | Elegant and timeless | Rudd Joinery">
      <meta name="description" content="The Sliding Sash windows we manufacture today combine an elegant and timeless look of that by gone era with the modern manufacturing techniques of today including dual weather seals, sustainable timber including Accoya® and a choice of stiles and finishes" />      
    </Helmet>
    <Hero heroImage="sliding-sash" heroId="hero" nextId="description">
      <h1>Sliding sash</h1>
      <h2>Elegant and timeless</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            Dating back to th 16th century, <strong>sliding sash</strong>{' '}
            windows are often found on Georgian and Victorian homes as well as
            being used to add a traditional touch to modern properties.
          </p>
          <p>
            The <strong>sliding sash</strong> windows we manufacture today
            combine an elegant and timeless look of that bygone era with the
            modern manufacturing techniques of today including dual weather
            seals, sustainable timber including Accoya&reg; and a choice of
            stiles and finishes.
          </p>
          <p>
            <img 
              src={ProductHero} 
              alt="Rudd Joinery | Sliding Sash" 
              className="product-hero"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            />
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky greenblue">
        <div className="expanded">
          <h3>Our sliding sash features</h3>
          <ul>
            <li>Dual high performance weather seals</li>
            <li>Spiral balance</li>
            <li>Weights and cords</li>
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__7">
          <h2>Dual weather seals</h2>
          <p>
            Featuring dual weather seals to further prevent weather ingress in
            exposed locations.
          </p>
        </div>
        <img src={WeatherSeals} alt="Dual weather seals" className="c__2"  />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>Spiral balance</h2>
          <p>
            An alternative to traditional weights &amp; cords is a concealed spiral balance offering smooth and easier opening and closing .
          </p>
        </div>
        <img src={SpiralBalance} alt="Spiral balance" className="c__3"  />
      </div>
      <div className="feature-bar">
        <div className="block c__7">
          <h2>Weights and cords</h2>
          <p>
            For that truly authentic look and feel, traditional cords and weights can be installed.
          </p>
        </div>
        <img src={WeightsCord} alt="Weights and cord" className="c__2"  />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>24mm toughened double glazing</h2>
          <p>
            High energy efficiency is further increased by argon filled double
            glazing.
          </p>
        </div>
        <img src={ToughenedGlass} alt="24mm toughened double glazing" className="c__5" />
      </div>
      <div className="cta-bar greenblue">
        <div className="expanded">
          <p>
            Our <strong>Sliding Sash</strong> window can be installed alongside
            our Flush and Stormproof windows as well as our range of doors, as
            all our mouldings are complimented across our product range.
          </p>          
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="sliding-sash" />
    <div className="section greenblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Sliding sash options</h2>
        </div>
        <ProductOptions ProductName="Sliding sash" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Sliding sash</h2>
          <p>
            Our bespoke <strong>Sliding Sash</strong> window can be manufactured
            to your specific requirements. If you need help, please do not
            hesitate to contact us on <strong>01485 529136</strong> or fill out
            our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default SlidingSash